import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

export const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

export let userName = "";

export const getUserIdfromToken = () => {
  const userId = localStorage.getItem("token");

  if (!userId) {
    return null;
  }
  return userId;
};

const initialState = {
  name: "",
  email: "",
  isJoining: "",
  role: "",
  status: "",
};

export const loginUser = createAsyncThunk(
  "user/login",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${baseUrl}/api/user/login`, userData, {
        withCredentials: true,
      });
      localStorage.setItem("userId", response?.data?.data?.user?._id);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const signupUser = createAsyncThunk(
  "user/signup",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/user/signup`,
        userData,
        {
          withCredentials: true,
        }
      );
      localStorage.setItem("userId", response?.data?.data?.user?._id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editUser = createAsyncThunk(
  "user/update",
  async (userData, { rejectWithValue }) => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.post(
        `${baseUrl}/api/user/edit/${userId}`,
        userData
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserData = createAsyncThunk(
  "user/getUserData",
  async (__, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/api/user/getUserData`, {
        withCredentials: true,
      });
      localStorage.setItem("token", response?.data?.data?._id);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const handleUploadPictureOnS3 = createAsyncThunk(
  "user/uploadImage",
  async (image, { rejectWithValue }) => {
    try {
      const userId = localStorage.getItem("userId");
      const formData = new FormData();
      formData.append("image", image);

      const response = await axios.post(
        `${baseUrl}/api/user/upload-image/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    fillUserData: (state, action) => {
      const { name, email, isJoining, role } = action.payload;
      state.name = name;
      state.email = email;
      state.isJoining = isJoining;
      state.role = role;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state) => {
      state.status = "success";
    });
    builder.addCase(loginUser.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(loginUser.rejected, (state) => {
      state.status = "failed";
    });
    builder.addCase(signupUser.fulfilled, (state) => {
      state.status = "success";
    });
    builder.addCase(signupUser.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(signupUser.rejected, (state) => {
      state.status = "failed";
    });
  },
});

export const { fillUserData } = authSlice.actions;
export default authSlice.reducer;
