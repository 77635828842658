import React from "react";

const InputField = ({ label, type, name, placeholder, value, onChange }) => {
  const acceptAttribute =
    type === "file" && name === "imageUpload" ? "image/*" : undefined;
  return (
    <div className="mb-3">
      <label className="block mb-1">{label}</label>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="border border-gray-300 p-2 w-full md:w-full"
        autoComplete="off"
        accept={acceptAttribute}
      />
    </div>
  );
};

export default InputField;
