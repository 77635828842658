import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../slice/authSlice";
import tripSlice from "../slice/tripSlice";

export const store = configureStore({
  reducer: {
    authReducer: authSlice,
    tripReducer: tripSlice,
  },
});

export default store;
