import React, { useState } from "react";
import InputField from "../InputField";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileInput from "../FileInput";
import { FaCheckCircle } from "react-icons/fa";

function TransportForm({ handleGetLocation, handleSubmit }) {
  const [deviceType, setDeviceType] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    contactNumber: "",
    alternateContactNumber: "",
    mailId: "",
    gst: "",
    brand: "",
    registrationTerm: "",
    interestLevel: "",
    vehicleNumber: "",
    licenseNumber: "",
    vehicleType: "",
    experience: "",
    clientAddress: "",
    clientPincode: "",
    clientCity: "",
    clientState: "",
    android: false,
    apple: false,
  });

  const interestLevelOptions = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ];

  const vehicleTypeOptions = [
    { label: "SCOOTY/SCOOTER", value: "SCOOTY/SCOOTER" },
    { label: "Bajaj Compact RE", value: "Bajaj Compact RE" },
    { label: "Mahindra Treo", value: "Mahindra Treo" },
    { label: "Bajaj Maxima Z", value: "Bajaj Maxima Z" },
    { label: "Piaggio Ape City Plus", value: "Piaggio Ape City Plus" },
    { label: "Mahindra Alfa", value: "Mahindra Alfa" },
    { label: "TVS King Deluxe", value: "TVS King Deluxe" },
    { label: "Mahindra Alfa Load", value: "Mahindra Alfa Load" },
    {
      label: "Lohia Humsafar Cargo Humsafar",
      value: "Lohia Humsafar Cargo Humsafar",
    },
    { label: "Piaggio Ape Xtra LDX", value: "Piaggio Ape Xtra LDX" },
    { label: "Mahindra Treo Zor", value: "Mahindra Treo Zor" },
    { label: "Euler HiLoad EV", value: "Euler HiLoad EV" },
    { label: "ETO Bulke", value: "ETO Bulke" },
    { label: "Taskman SmartAuto L5", value: "Taskman SmartAuto L5" },
    { label: "Kinetic Safar Star 400 L5", value: "Kinetic Safar Star 400 L5" },
    { label: "Li-ions Elektrik L5", value: "Li-ions Elektrik L5" },
    { label: "Vidhyut C1 L5", value: "Vidhyut C1 L5" },
    { label: "Bajaj Maxima C", value: "Bajaj Maxima C" },
    { label: "Mahindra Alfa Plus", value: "Mahindra Alfa Plus" },
    { label: "Mahindra Zor Grand", value: "Mahindra Zor Grand" },
    { label: "TATA ACE", value: "TATA ACE" },
    { label: "ASHOK LEYLAND DOST", value: "ASHOK LEYLAND DOST" },
    { label: "MAHINDRA BOLERO PICK UP", value: "MAHINDRA BOLERO PICK UP" },
    { label: "ASHOK LEYLAND BADA DOST", value: "ASHOK LEYLAND BADA DOST" },
    { label: "TATA 407", value: "TATA 407" },
    { label: "EICHER 14 FEET", value: "EICHER 14 FEET" },
    { label: "EICHER 17 FEET", value: "EICHER 17 FEET" },
    { label: "EICHER 19 FEET", value: "EICHER 19 FEET" },
    { label: "TATA 22 FEET", value: "TATA 22 FEET" },
    { label: "TATA TRUCK (6 TYRE)", value: "TATA TRUCK (6 TYRE)" },
    { label: "TAURUS 16 T (10 TYRE)", value: "TAURUS 16 T (10 TYRE)" },
    { label: "TAURUS 21 T (12 TYRE)", value: "TAURUS 21 T (12 TYRE)" },
    { label: "TAURUS 25 T (14 TYRE)", value: "TAURUS 25 T (14 TYRE)" },
    { label: "CONTAINER 20 FT", value: "CONTAINER 20 FT" },
    { label: "CONTAINER 32 FT SXL", value: "CONTAINER 32 FT SXL" },
    { label: "CONTAINER 32 FT MXL", value: "CONTAINER 32 FT MXL" },
    {
      label: "CONTAINER 32 FT SXL / MXL HQ",
      value: "CONTAINER 32 FT SXL / MXL HQ",
    },
    {
      label: "20 FEET OPEN ALL SIDE (ODC)",
      value: "20 FEET OPEN ALL SIDE (ODC)",
    },
    {
      label: "28-32 FEET OPEN-TRAILOR JCB ODC",
      value: "28-32 FEET OPEN-TRAILOR JCB ODC",
    },
    { label: "32 FEET OPEN-TRAILOR ODC", value: "32 FEET OPEN-TRAILOR ODC" },
    { label: "40 FEET OPEN-TRAILOR ODC", value: "40 FEET OPEN-TRAILOR ODC" },
  ];

  const handleSelectChange = (name, selectedOption) => {
    setFormData({
      ...formData,
      [name]: selectedOption.value,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    setFormData((prevData) => ({
      ...prevData,
      dataType: "Transport",
    }));

    handleSubmit(formData);
  };

  return (
    <form className="mt-4 space-y-4">
      <InputField
        label="Name"
        type="text"
        id="name"
        name="name"
        placeholder="Enter your name"
        value={formData.name}
        onChange={handleChange}
      />
      <div className="flex gap-2">
        <InputField
          label="Contact Number"
          type="number"
          name="contactNumber"
          placeholder="Enter mobile number"
          value={formData.contactNumber}
          onChange={handleChange}
        />
        <InputField
          label="Alternate Number"
          type="number"
          name="alternateContactNumber"
          placeholder="Enter mobile number"
          value={formData.alternateContactNumber}
          onChange={handleChange}
        />
      </div>
      <InputField
        label="Mail ID"
        type="email"
        name="mailId"
        placeholder="Enter email id"
        value={formData.mailId}
        onChange={handleChange}
      />
      <InputField
        label="GST No."
        type="text"
        id="gst"
        name="gst"
        placeholder="Enter your GST"
        value={formData.gst}
        onChange={handleChange}
      />
      <InputField
        label="Brand"
        type="text"
        name="brand"
        placeholder="Enter your brand"
        value={formData.brand}
        onChange={handleChange}
      />
      <InputField
        label="Registration Term"
        type="text"
        name="registrationTerm"
        placeholder="Enter your Registration Term"
        value={formData.registrationTerm}
        onChange={handleChange}
      />
      <div>
        <p className="mb-1">Interest Level</p>
        <Select
          placeholder="Choose Your Interest level"
          onChange={(selectedOption) =>
            handleSelectChange("interestLevel", selectedOption)
          }
          options={interestLevelOptions}
        />
      </div>
      <InputField
        label="Vehicle Number"
        type="text"
        name="vehicleNumber"
        placeholder="Enter Vehicle Number"
        value={formData.vehicleNumber}
        onChange={handleChange}
      />
      <InputField
        label="License Number"
        type="text"
        name="licenseNumber"
        placeholder="Enter License Number"
        value={formData.licenseNumber}
        onChange={handleChange}
      />
      <div>
        <p className="mb-1">Vehicle Type</p>
        <Select
          placeholder="Select Your vehicle type"
          onChange={(selectedOption) =>
            handleSelectChange("vehicleType", selectedOption)
          }
          options={vehicleTypeOptions}
        />
      </div>
      <InputField
        label="Experience"
        type="text"
        name="experience"
        placeholder="Enter your Experience"
        value={formData.experience}
        onChange={handleChange}
      />
      <h3 className="text-xl font-semibold text-center mb-4 text-auth">
        Client/Vendor Details
      </h3>
      <InputField
        label="Address"
        type="text"
        id="clientAddress"
        name="clientAddress"
        placeholder="Enter Client Address"
        value={formData.clientAddress}
        onChange={handleChange}
      />
      <div className="flex gap-2 items-center">
        <InputField
          label="City"
          type="text"
          id="clientCity"
          name="clientCity"
          placeholder="Enter City"
          value={formData.clientCity}
          onChange={handleChange}
        />
        <InputField
          label="State"
          type="text"
          id="clientState"
          name="clientState"
          placeholder="Enter State"
          value={formData.clientState}
          onChange={handleChange}
        />
      </div>
      <InputField
        label="Pincode"
        type="number"
        id="clientPincode"
        name="clientPincode"
        placeholder="Enter Pincode"
        value={formData.clientPincode}
        onChange={handleChange}
      />
      <div className="flex items-center">
        <button
          type="button"
          className="btn bg-[#ff7703] text-white px-4 mt-3 mr-5 py-1 rounded-lg"
          onClick={() => handleGetLocation(setFormData, formData)}
        >
          Get Current Location
        </button>
        {formData.gpsLocation ? (
          <FaCheckCircle
            style={{ fontSize: "24px", color: "green", marginTop: "12px" }}
          />
        ) : null}
      </div>
      <div className="flex justify-start items-center">
        <div className="mr-3">
          <p>Device Type</p>
        </div>
        <button
          type="button"
          className={`btn mr-4 border-black border px-2 py-1 ${
            deviceType === "Android"
              ? "bg-black text-white"
              : "bg-white text-black"
          }`}
          onClick={() => setDeviceType("Android")}
        >
          Android
        </button>
        <button
          type="button"
          className={`btn mr-4 border-black border px-2 py-1 ${
            deviceType === "Apple"
              ? "bg-black text-white"
              : "bg-white text-black"
          }`}
          onClick={() => setDeviceType("Apple")}
        >
          Apple
        </button>
      </div>
      <FileInput
        label="Upload Image"
        type="file"
        id="imageUpload"
        name="imageUpload"
        formData={formData}
        setFormData={setFormData}
      />
      <div className="flex justify-between">
        <button
          type="submit"
          onClick={(e) => handleFormSubmit(e)}
          className="bg-[#ff7703] text-white py-2 px-4 mt-4 rounded-lg"
        >
          Submit
        </button>
        <button
          type="button"
          //   onClick={() => navigate("/dataCenter")}
          className="border-[#ff7703] border text-black py-2 px-4 mt-4 rounded-lg"
        >
          Next
        </button>
      </div>
    </form>
  );
}

export default TransportForm;
