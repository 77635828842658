import React, { useState } from "react";
import InputField from "../InputField";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileInput from "../FileInput";
import { FaCheckCircle } from "react-icons/fa";

function VendorForm({ handleGetLocation, handleSubmit }) {
  const [deviceType, setDeviceType] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    contactNumber: "",
    alternateContactNumber: "",
    mailId: "",
    gst: "",
    brand: "",
    registrationTerm: "",
    interestLevel: "",
    department: "",
    clientAddress: "",
    clientPincode: "",
    clientCity: "",
    clientState: "",
    android: false,
    apple: false,
  });

  const registrationTermOptions = [
    { label: "1 month", value: "1 month" },
    { label: "2 months", value: "2 months" },
    { label: "3 months", value: "3 months" },
    { label: "6 months", value: "6 months" },
    { label: "9 months", value: "9 months" },
    { label: "1 year", value: "1 year" },
    { label: "2 years", value: "2 years" },
    { label: "More than 2 years", value: "More than 2 years" },
  ];

  const interestLevelOptions = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ];

  const departmentOptions = [
    { label: "Carpenter", value: "Carpenter" },
    { label: "Modular Kitchen", value: "Modular Kitchen" },
    { label: "Electrical Wiring", value: "Electrical Wiring" },
    { label: "Painting Work", value: "Painting Work" },
    { label: "Furniture", value: "Furniture" },
    { label: "Sofa", value: "Sofa" },
    { label: "Mattress", value: "Mattress" },
    { label: "Flooring Tiles Work", value: "Flooring Tiles Work" },
    { label: "Fabrications", value: "Fabrications" },
    { label: "Sliding Windows", value: "Sliding Windows" },
    { label: "Marble & Granite", value: "Marble & Granite" },
    { label: "Brick Wall & Plaster", value: "Brick Wall & Plaster" },
    { label: "Water Proofing", value: "Water Proofing" },
    { label: "RCC Steel Fitter", value: "RCC Steel Fitter" },
    { label: "Interior Designer", value: "Interior Designer" },
    { label: "Architect", value: "Architect" },
    { label: "RCC Consultant", value: "RCC Consultant" },
    { label: "POP Work", value: "POP Work" },
  ];

  const handleSelectChange = (name, selectedOption) => {
    setFormData({
      ...formData,
      [name]: selectedOption.value,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    setFormData((prevData) => ({
      ...prevData,
      dataType: "Vendor",
    }));

    handleSubmit(formData);
  };

  return (
    <form className="mt-4 space-y-4">
      <InputField
        label="Name"
        type="text"
        id="name"
        name="name"
        placeholder="Enter your name"
        value={formData.name}
        onChange={handleChange}
      />
      <div className="flex gap-2">
        <InputField
          label="Contact Number"
          type="number"
          name="contactNumber"
          placeholder="Enter mobile number"
          value={formData.contactNumber}
          onChange={handleChange}
        />
        <InputField
          label="Alternate Number"
          type="number"
          name="alternateContactNumber"
          placeholder="Enter mobile number"
          value={formData.alternateContactNumber}
          onChange={handleChange}
        />
      </div>
      <InputField
        label="Mail ID"
        type="email"
        name="mailId"
        placeholder="Enter email id"
        value={formData.mailId}
        onChange={handleChange}
      />
      <InputField
        label="GST No."
        type="text"
        id="gst"
        name="gst"
        placeholder="Enter your GST"
        value={formData.gst}
        onChange={handleChange}
      />
      <InputField
        label="Brand"
        type="text"
        name="brand"
        placeholder="Enter your brand"
        value={formData.brand}
        onChange={handleChange}
      />
      <div>
        <p className="mb-1">Registration Term</p>
        <Select
          placeholder="Registration term..."
          onChange={(selectedOption) =>
            handleSelectChange("registrationTerm", selectedOption)
          }
          options={registrationTermOptions}
        />
      </div>
      <div>
        <p className="mb-1">Interest Level</p>
        <Select
          placeholder="Choose Your Interest level"
          onChange={(selectedOption) =>
            handleSelectChange("interestLevel", selectedOption)
          }
          options={interestLevelOptions}
        />
      </div>
      <div>
        <p className="mb-1">Department</p>
        <Select
          placeholder="Select Your department"
          onChange={(selectedOption) =>
            handleSelectChange("department", selectedOption)
          }
          options={departmentOptions}
        />
      </div>
      <h3 className="text-xl font-semibold text-center mb-4 text-auth">
        Client/Vendor Details
      </h3>
      <InputField
        label="Address"
        type="text"
        id="clientAddress"
        name="clientAddress"
        placeholder="Enter Client Address"
        value={formData.clientAddress}
        onChange={handleChange}
      />
      <div className="flex gap-2 items-center">
        <InputField
          label="City"
          type="text"
          id="clientCity"
          name="clientCity"
          placeholder="Enter City"
          value={formData.clientCity}
          onChange={handleChange}
        />
        <InputField
          label="State"
          type="text"
          id="clientState"
          name="clientState"
          placeholder="Enter State"
          value={formData.clientState}
          onChange={handleChange}
        />
      </div>
      <InputField
        label="Pincode"
        type="number"
        id="clientPincode"
        name="clientPincode"
        placeholder="Enter Pincode"
        value={formData.clientPincode}
        onChange={handleChange}
      />
      <div className="flex items-center">
        <button
          type="button"
          className="btn bg-[#ff7703] text-white px-4 mt-3 mr-5 py-1 rounded-lg"
          onClick={() => handleGetLocation(setFormData, formData)}
        >
          Get Current Location
        </button>
        {formData.gpsLocation ? (
          <FaCheckCircle
            style={{ fontSize: "24px", color: "green", marginTop: "12px" }}
          />
        ) : null}
      </div>
      <div className="flex justify-start items-center">
        <div className="mr-3">
          <p>Device Type</p>
        </div>
        <button
          type="button"
          className={`btn mr-4 border-black border px-2 py-1 ${
            deviceType === "Android"
              ? "bg-black text-white"
              : "bg-white text-black"
          }`}
          onClick={() => setDeviceType("Android")}
        >
          Android
        </button>
        <button
          type="button"
          className={`btn mr-4 border-black border px-2 py-1 ${
            deviceType === "Apple"
              ? "bg-black text-white"
              : "bg-white text-black"
          }`}
          onClick={() => setDeviceType("Apple")}
        >
          Apple
        </button>
      </div>
      <FileInput
        label="Upload Image"
        type="file"
        id="imageUpload"
        name="imageUpload"
        formData={formData}
        setFormData={setFormData}
      />
      <div className="flex justify-between">
        <button
          type="submit"
          onClick={(e) => handleFormSubmit(e)}
          className="bg-[#ff7703] text-white py-2 px-4 mt-4 rounded-lg"
        >
          Submit
        </button>
        <button
          type="button"
          //   onClick={() => navigate("/dataCenter")}
          className="border-[#ff7703] border text-black py-2 px-4 mt-4 rounded-lg"
        >
          Next
        </button>
      </div>
    </form>
  );
}

export default VendorForm;
