import React, { useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { uploadImageForSalesForm } from "../redux/slice/formSlice";

const FileInput = ({ label, name, formData, setFormData }) => {
  const [imagePreviews, setImagePreviews] = useState([]);
  const dispatch = useDispatch();

  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);

    const fileURLs = files.map((file) => URL.createObjectURL(file));
    setImagePreviews(fileURLs);

    const response = await dispatch(uploadImageForSalesForm(files[0]));
    const imageURL = response?.payload?.data;
    console.log(imageURL);

    setFormData((prevFormData) => ({
      ...prevFormData,
      imagesURL: imageURL,
    }));
  };

  const removeImage = (indexToRemove) => {
    const updatedImages = imagePreviews.filter(
      (_, index) => index !== indexToRemove
    );
    setImagePreviews(updatedImages);
  };
  return (
    <>
      <div className="mb-3">
        <label className="block mb-1">{label}</label>
        <input
          type="file"
          name={name}
          onChange={handleImageChange}
          className="border border-gray-300 p-2 w-full md:w-full"
          autoComplete="off"
          accept="image/*"
          // multiple
        />
      </div>
      <div className="flex flex-wrap">
        {imagePreviews.map((preview, index) => (
          <div key={index} className="relative">
            <img
              src={preview}
              alt={`Preview ${index}`}
              className="w-32 h-32 object-cover m-2"
            />
            <button
              type="button"
              className="absolute top-0 right-0 mt-1 mr-1cursor-pointer"
              onClick={() => removeImage(index)}
            >
              <RxCrossCircled style={{ fontSize: "18px", color: "red" }} />
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export default FileInput;
