import React, { useEffect, useState } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import InputField from "../components/InputField";
import { useDispatch } from "react-redux";
import {
  editUser,
  getUserData,
  handleUploadPictureOnS3,
} from "../redux/slice/authSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserData from "../components/UserData";
import Select from "react-select";

const Form = () => {
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState(null);
  const [isshowForm, setIsShowForm] = useState(true);
  const [userData, setUserData] = useState();
  const [formData, setFormData] = useState({
    name: "",
    age: "",
    contactNumber: "",
    bloodGroup: "",
    email: "",
    fathersName: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    designation: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === "age" ? parseInt(value, 10) || "" : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleSubmit = async () => {
    try {
      const response = await dispatch(editUser(formData));

      if (response?.error) {
        console.log(response);
        const message = response?.payload?.msg?.publicError;
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      const msg = response?.payload?.msg;

      toast.success(msg, {
        position: toast.POSITION.TOP_CENTER,
      });

      const fileInput = document.getElementById("imageUpload");
      const file = fileInput.files[0];

      if (file) {
        const imageResponse = await dispatch(handleUploadPictureOnS3(file));
        console.log("Image upload response:", imageResponse);
      }

      setUserData(response?.payload?.data);
      setIsShowForm(false);
    } catch (error) {
      console.log("Error saving data:", error);
    }
  };

  const getUserDataFunction = async () => {
    const response = await dispatch(getUserData());
    const data = response?.payload?.data;
    setIsShowForm(!data?.isJoining);
    setUserData(data);
    setFormData(data);
    setImagePreview(data?.image);
  };

  const handleSelectChange = (name, selectedOption) => {
    setFormData({
      ...formData,
      [name]: selectedOption.value,
    });
  };

  useEffect(() => {
    getUserDataFunction();
  }, []);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const imageURL = URL.createObjectURL(file);
      setImagePreview(imageURL);
    }
  };

  const designationOptions = [
    { label: "Sales", value: "sales" },
    { label: "Marketing", value: "marketing" },
    { label: "Data Associate", value: "dataAssociate" },
  ];

  const bloodGroupOptions = [
    { label: "A-", value: "A-" },
    { label: "A+", value: "A+" },
    { label: "B-", value: "B-" },
    { label: "B+", value: "B+" },
    { label: "O+", value: "O+" },
    { label: "O-", value: "O-" },
    { label: "AB+", value: "AB+" },
    { label: "AB-", value: "AB-" },
  ];

  return (
    <>
      <div className="min-h-full flex items-center md:justify-center px-8">
        <div className="py-8 px-0 flex flex-col form-css">
          {isshowForm ? (
            <>
              <div className="order-1 md:order-1 md:mr-12 my-4 md:my-auto flex justify-center">
                <div className="relative inline-block">
                  <img
                    src={imagePreview || "https://via.placeholder.com/150"}
                    alt="User"
                    className="w-40 h-40 rounded-full"
                  />
                  <input
                    type="file"
                    id="imageUpload"
                    name="imageUpload"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="hidden absolute bottom-0 right-0 m-2"
                  />
                  <button
                    onClick={() =>
                      document.getElementById("imageUpload").click()
                    }
                    className="py-2 px-4 absolute -bottom-4 right-0"
                  >
                    <FaCloudUploadAlt
                      style={{ fontSize: "30px", color: "grey" }}
                    />
                  </button>
                </div>
              </div>
              <div className="order-1 md:order-2">
                <h2 className="text-2xl font-semibold mb-4 md:mt-4">
                  User Information
                </h2>
                <div className="sm:flex items-center gap-2">
                  <InputField
                    label="Name"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter your name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <div className="flex items-center gap-2">
                    <InputField
                      label="Age"
                      type="number"
                      id="age"
                      name="age"
                      placeholder="Enter your age"
                      value={formData.age}
                      onChange={handleChange}
                    />
                    <InputField
                      label="Contact Number"
                      type="number"
                      id="contactNumber"
                      name="contactNumber"
                      placeholder="Contact Number"
                      value={formData.contactNumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <label>Blood Group</label>
                <Select
                  placeholder="Select Your BloodGroup"
                  onChange={(selectedOption) =>
                    handleSelectChange("bloodGroup", selectedOption)
                  }
                  options={bloodGroupOptions}
                  className="mb-2"
                />
                <InputField
                  label="Email"
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <InputField
                  label="Father's Name"
                  type="text"
                  id="fathersName"
                  name="fathersName"
                  placeholder="Enter father's name"
                  value={formData.fathersName}
                  onChange={handleChange}
                />
                <div className="flex items-center gap-2">
                  <InputField
                    label="City"
                    type="text"
                    id="city"
                    name="city"
                    placeholder="Enter your city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                  <InputField
                    label="State"
                    type="text"
                    id="state"
                    name="state"
                    placeholder="Enter your state"
                    value={formData.state}
                    onChange={handleChange}
                  />
                </div>
                <InputField
                  label="Pincode"
                  type="number"
                  id="pincode"
                  name="pincode"
                  placeholder="Enter your Pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                />
                <InputField
                  label="Address"
                  type="text"
                  id="address"
                  name="address"
                  placeholder="Enter your Address"
                  value={formData.address}
                  onChange={handleChange}
                />
                <p className="mb-1">Designations</p>
                <Select
                  placeholder="Select Your designation"
                  onChange={(selectedOption) =>
                    handleSelectChange("designation", selectedOption)
                  }
                  options={designationOptions}
                  className="mb-2"
                />
                <div className="flex justify-between">
                  <button
                    onClick={handleSubmit}
                    className="bg-[#ff7703] text-white py-2 px-4 mt-4 rounded-lg"
                  >
                    Save
                  </button>
                  <button
                    onClick={() =>
                      setFormData({
                        name: "",
                        age: "",
                        contactNumber: "",
                        bloodGroup: "",
                        email: "",
                        fathersName: "",
                        address: "",
                        city: "",
                        state: "",
                        pincode: "",
                        designation: "",
                      })
                    }
                    className="border-[#ff7703] border text-black py-2 px-4 mt-4 rounded-lg"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </>
          ) : (
            <UserData userData={userData} setIsShowForm={setIsShowForm} />
          )}
        </div>
      </div>
    </>
  );
};

export default Form;
