import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { MdMenu } from "react-icons/md";

const TopLeftDrawer = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(isOpen);
  };

  const navigateTo = (route) => {
    setOpen(false);
    navigate(route);
  };

  const logout = () => {
    localStorage.removeItem('userId')
    localStorage.removeItem('token')
    localStorage.removeItem('tripId')
    localStorage.removeItem('timeArray')
  }

  return (
    <div className="flex items-center">
      <Button
        onClick={toggleDrawer(true)}
        className="ml-4"
        style={{
          color: "#000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // width: "40px",
          // height: "40px",
          border: "none",
          "&:hover": {
            backgroundColor: "transparent",
            color: "#333",
          },
        }}
      >
        <MdMenu style={{ fontSize: "30px" }} />
      </Button>

      <Drawer
        variant="temporary"
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        ModalProps={{
          keepMounted: true,
        }}
        className="ml-4"
        sx={{
          "& .MuiDrawer-paper": {
            width: 240,
            boxSizing: "border-box",
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "1rem",
          },
        }}
      >
        <List sx={{ width: "100%" }}>
          <ListItem
            onClick={() => navigateTo("/form")}
            sx={{
              width: "100%",
              justifyContent: "center",
              borderBottom: "1px solid #ccc",
              "&:hover": {
                backgroundColor: "#e0e0e0",
                cursor: "pointer",
              },
            }}
          >
            <ListItemText primary="User Details" />
          </ListItem>
          <ListItem
            onClick={() => navigateTo("/tripData")}
            sx={{
              width: "100%",
              justifyContent: "center",
              borderBottom: "1px solid #ccc",
              "&:hover": {
                backgroundColor: "#e0e0e0",
                cursor: "pointer",
              },
            }}
          >
            <ListItemText primary="Trip Data" />
          </ListItem>
          <ListItem
            onClick={() => navigateTo("/salesForm")}
            sx={{
              width: "100%",
              justifyContent: "center",
              borderBottom: "1px solid #ccc",
              "&:hover": {
                backgroundColor: "#e0e0e0",
                cursor: "pointer",
              },
            }}
          >
            <ListItemText primary="Sales Form" />
          </ListItem>
          <ListItem
            onClick={() => navigateTo("/dataCenter")}
            sx={{
              width: "100%",
              justifyContent: "center",
              borderBottom: "1px solid #ccc",
              "&:hover": {
                backgroundColor: "#e0e0e0",
                cursor: "pointer",
              },
            }}
          >
            <ListItemText primary="Data Center" />
          </ListItem>
          <ListItem
            onClick={() => navigateTo("/")}
            sx={{
              width: "100%",
              justifyContent: "center",
              borderBottom: "1px solid #ccc",
              "&:hover": {
                backgroundColor: "#e0e0e0",
                cursor: "pointer",
              },
            }}
          >
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
};

export default TopLeftDrawer;
