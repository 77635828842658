import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { editSalesData } from "../../redux/slice/formSlice";
import FileInput from "../FileInput";

const EditSalesData = ({ data, setEditMode }) => {
  const [editedData, setEditedData] = useState({ ...data });
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await dispatch(editSalesData(editedData));
    console.log(response);
    setEditMode(false);
  };

  return (
    <div className="fixed top-0 left-0 w-full min-h-screen overflow-auto bg-black bg-opacity-40 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded-md shadow-md w-full max-w-lg sales-form-css overflow-y-auto">
        <h2 className="text-xl font-semibold mb-4 text-center">
          {data.dataType}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {Object.entries(editedData)
            .filter(
              ([key]) =>
                ![
                  "_id",
                  "__v",
                  "userId",
                  "date",
                  "dataType",
                  "android",
                  "apple",
                  "updatedAt",
                  "createdAt",
                  "imagesURL",
                  "gpsLocation",
                ].includes(key)
            )
            .map(([key, value]) => (
              <div key={key}>
                <label className="block mb-1 font-medium">
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </label>
                <input
                  type="text"
                  value={value}
                  name={key}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md p-2 w-full"
                />
              </div>
            ))}
          <FileInput
            label="Upload Image"
            type="file"
            id="imageUpload"
            name="imageUpload"
            formData={editedData}
            setFormData={setEditedData}
          />
          <div className="flex flex-col sm:flex-row items-center justify-between">
            <button
              type="submit"
              className="bg-[#ff7703] text-white py-2 px-4 mt-4 w-full sm:w-auto"
            >
              Save Changes
            </button>
            <button
              onClick={(e) => setEditMode(false)}
              className="border-[#ff7703] border text-black py-2 px-4 rounded-md mt-4 w-full sm:w-auto"
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditSalesData;
