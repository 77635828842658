import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { monitorTripData } from "../../redux/slice/tripSlice";
import { useDispatch, useSelector } from "react-redux";

const TripDataTable = ({ tripData }) => {
  const dispatch = useDispatch();
  const { latitude, longitude } = useSelector((state) => state.tripReducer);
  const [tripArray, setTripArray] = useState([]);

  const timeStamps = localStorage.getItem("timeArray");
  const timeArray = timeStamps ? JSON.parse(timeStamps) : [];

  useEffect(() => {
    const getTripDataFunction = async () => {
      try {
        const response = await dispatch(monitorTripData());
        setTripArray(response?.payload?.data);
        console.log("latitude", latitude);
        console.log("longitude", longitude);
      } catch (error) {
        console.log(error);
      }
    };
    getTripDataFunction();
  }, [dispatch, timeStamps]);

  return (
    <div className="w-full max-h-[300px] overflow-auto">
      <TableContainer component={Paper}>
        <Table aria-label="trip data table">
          <TableHead>
            <TableRow>
              <TableCell>Time</TableCell>
              <TableCell>Latitude</TableCell>
              <TableCell>Longitude</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tripArray?.latitude?.length > 0
              ? tripArray?.latitude?.map((lat, index) => (
                  <TableRow key={index}>
                    <TableCell>{timeArray[index]}</TableCell>
                    <TableCell>{latitude[index]?.toFixed(3)}</TableCell>
                    <TableCell>{longitude[index]?.toFixed(3)}</TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TripDataTable;
