import React, { useState } from "react";
import InputField from "../InputField";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileInput from "../FileInput";
import { FaCheckCircle } from "react-icons/fa";

function ChannelPartnerForm({ handleGetLocation, handleSubmit }) {
  const [deviceType, setDeviceType] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    contactNumber: "",
    alternateContactNumber: "",
    mailId: "",
    gst: "",
    warehouseAddress: "",
    brandName: "",
    cateogoryOfProducts: "",
    interestLevel: "",
    pincode: "",
    city: "",
    state: "",
    supplierCategory: "",
    android: false,
    apple: false,
  });

  const interestLevelOptions = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ];

  const supplierCategoryOptions = [
    { label: "Dealer", value: "Dealer" },
    { label: "Distributor", value: "Distributor" },
    { label: "Retailer", value: "Retailer" },
  ];

  const handleSelectChange = (name, selectedOption) => {
    setFormData({
      ...formData,
      [name]: selectedOption.value,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    setFormData((prevData) => ({
      ...prevData,
      dataType: "Channel Partner",
    }));

    handleSubmit(formData);
  };

  return (
    <form className="mt-4 space-y-4">
      <InputField
        label="Name"
        type="text"
        id="name"
        name="name"
        placeholder="Enter your name"
        value={formData.name}
        onChange={handleChange}
      />
      <div className="flex gap-2">
        <InputField
          label="Contact Number"
          type="number"
          name="contactNumber"
          placeholder="Enter mobile number"
          value={formData.contactNumber}
          onChange={handleChange}
        />
        <InputField
          label="Alternate Number"
          type="number"
          name="alternateContactNumber"
          placeholder="Enter mobile number"
          value={formData.alternateContactNumber}
          onChange={handleChange}
        />
      </div>
      <InputField
        label="Mail ID"
        type="email"
        name="mailId"
        placeholder="Enter email id"
        value={formData.mailId}
        onChange={handleChange}
      />
      <InputField
        label="GST No."
        type="text"
        id="gst"
        name="gst"
        placeholder="Enter your GST"
        value={formData.gst}
        onChange={handleChange}
      />
      <InputField
        label="WareHouse/ShowRoom Address"
        type="text"
        name="warehouseAddress"
        placeholder="Enter your brand"
        value={formData.warehouseAddress}
        onChange={handleChange}
      />
      <InputField
        label="Brand Name"
        type="text"
        name="brandName"
        placeholder="Enter your Brand Name"
        value={formData.brandName}
        onChange={handleChange}
      />
      <InputField
        label="Category of Products"
        type="text"
        name="cateogoryOfProducts"
        placeholder="Enter your Brand Name"
        value={formData.cateogoryOfProducts}
        onChange={handleChange}
      />
      <div className="flex gap-2 items-center">
        <InputField
          label="City"
          type="text"
          name="city"
          placeholder="Enter City"
          value={formData.city}
          onChange={handleChange}
        />
        <InputField
          label="State"
          type="text"
          name="state"
          placeholder="Enter State"
          value={formData.state}
          onChange={handleChange}
        />
      </div>
      <InputField
        label="Pincode"
        type="number"
        name="pincode"
        placeholder="Enter Pincode"
        value={formData.pincode}
        onChange={handleChange}
      />
      <div>
        <p className="mb-1">Interest Level</p>
        <Select
          placeholder="Choose Your Interest level"
          onChange={(selectedOption) =>
            handleSelectChange("interestLevel", selectedOption)
          }
          options={interestLevelOptions}
        />
      </div>
      <div>
        <p className="mb-1">Supplier Category</p>
        <Select
          placeholder="Supplier Cateogory"
          onChange={(selectedOption) =>
            handleSelectChange("supplierCategory", selectedOption)
          }
          options={supplierCategoryOptions}
        />
      </div>
      <div className="flex items-center">
        <button
          type="button"
          className="btn bg-[#ff7703] text-white px-4 mt-3 mr-5 py-1 rounded-lg"
          onClick={() => handleGetLocation(setFormData, formData)}
        >
          Get Current Location
        </button>
        {formData.gpsLocation ? (
          <FaCheckCircle
            style={{ fontSize: "24px", color: "green", marginTop: "12px" }}
          />
        ) : null}
      </div>
      <div className="flex justify-start items-center">
        <div className="mr-3">
          <p>Device Type</p>
        </div>
        <button
          type="button"
          className={`btn mr-4 border-black border px-2 py-1 ${
            deviceType === "Android"
              ? "bg-black text-white"
              : "bg-white text-black"
          }`}
          onClick={() => setDeviceType("Android")}
        >
          Android
        </button>
        <button
          type="button"
          className={`btn mr-4 border-black border px-2 py-1 ${
            deviceType === "Apple"
              ? "bg-black text-white"
              : "bg-white text-black"
          }`}
          onClick={() => setDeviceType("Apple")}
        >
          Apple
        </button>
      </div>
      <FileInput
        label="Upload Image"
        type="file"
        id="imageUpload"
        name="imageUpload"
        formData={formData}
        setFormData={setFormData}
      />
      <div className="flex justify-between">
        <button
          onClick={(e) => handleFormSubmit(e)}
          type="submit"
          className="bg-[#ff7703] text-white py-2 px-4 mt-4 rounded-lg"
        >
          Submit
        </button>
        <button
          type="button"
          //   onClick={() => navigate("/dataCenter")}
          className="border-[#ff7703] border text-black py-2 px-4 mt-4 rounded-lg"
        >
          Next
        </button>
      </div>
    </form>
  );
}

export default ChannelPartnerForm;
