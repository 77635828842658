import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { saveSalesFormData } from "../redux/slice/formSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import CustomerForm from "../components/SalesForm/CustomerForm";
import VendorForm from "../components/SalesForm/VendorForm";
import TransportForm from "../components/SalesForm/TransportForm";
import ChannelPartnerForm from "../components/SalesForm/ChannelPartnerForm";

const SalesForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedHeading, setSelectedHeading] = useState("Customer");

  const handleSubmit = async (formData) => {
    const updatedFormData = { ...formData, dataType: selectedHeading };

    try {
      const response = await dispatch(saveSalesFormData(updatedFormData));

      const msg = response?.payload?.msg;

      if (response?.error) {
        console.log(response);
        const message = response?.payload;
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      toast.success(msg, {
        position: toast.POSITION.TOP_CENTER,
      });
      navigate("/dataCenter");
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetLocation = (setFormData, formData) => {
    if (formData.gpsLocation) {
      toast.info("GPS Location has already been recorded", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const newLocation = `${latitude},${longitude}`;
          setFormData((prevData) => ({
            ...prevData,
            gpsLocation: newLocation,
          }));
        },
        (error) => {
          console.log("Error getting geolocation:", error);
        }
      );
      toast.success("Location updated", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const renderFieldsBasedOnHeading = () => {
    switch (selectedHeading) {
      case "Customer":
        return (
          <CustomerForm
            handleGetLocation={handleGetLocation}
            handleSubmit={handleSubmit}
          />
        );
      case "Vendor":
        return (
          <VendorForm
            handleGetLocation={handleGetLocation}
            handleSubmit={handleSubmit}
          />
        );
      case "Transport":
        return (
          <TransportForm
            handleGetLocation={handleGetLocation}
            handleSubmit={handleSubmit}
          />
        );
      case "Channel Partner":
        return (
          <ChannelPartnerForm
            handleGetLocation={handleGetLocation}
            handleSubmit={handleSubmit}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex items-center justify-center min-h-full">
      <div className="bg-white p-8 w-full max-w-[35rem]">
        <div className="flex flex-wrap justify-center space-x-5 items-center mb-5">
          <button
            className={`btn ${
              selectedHeading === "Customer" ? "bg-black text-white p-2" : ""
            }`}
            onClick={() => setSelectedHeading("Customer")}
          >
            Customer
          </button>
          <button
            className={`btn ${
              selectedHeading === "Vendor" ? "bg-black text-white p-2" : ""
            }`}
            onClick={() => setSelectedHeading("Vendor")}
          >
            Vendor
          </button>
          <button
            className={`btn ${
              selectedHeading === "Transport" ? "bg-black text-white p-2" : ""
            }`}
            onClick={() => setSelectedHeading("Transport")}
          >
            Transport
          </button>
          <button
            className={`btn text-center mt-3 md:mt-0 mx-auto ${
              selectedHeading === "Channel Partner"
                ? "bg-black text-white p-2"
                : ""
            }`}
            onClick={() => setSelectedHeading("Channel Partner")}
          >
            Channel Partner
          </button>
        </div>
        <div>
          <h3 className="text-xl font-semibold text-center mb-4 text-auth">
            {selectedHeading}
          </h3>
          {renderFieldsBasedOnHeading()}
        </div>
      </div>
    </div>
  );
};

export default SalesForm;
