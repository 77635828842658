import React, { useRef, useEffect } from "react";
import * as faceapi from "face-api.js";
import Webcam from "react-webcam";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const videoConstraints = {
  width: 800,
  height: 600,
  facingMode: "user",
};

const referencedImageURL =
  "https://plypicker.s3.ap-south-1.amazonaws.com/user_1703233221979.png";

async function fetchAndConvertImageToBase64(imageURL) {
  try {
    const response = await fetch(
      "https://cors-anywhere.herokuapp.com/" + imageURL
    );
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = function () {
        const base64URL = reader.result;
        resolve(base64URL);
      };
      reader.onerror = function (error) {
        reject(error);
      };
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error fetching image:", error);
    return null;
  }
}

function Attendance() {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    loadModelsAndStartVideo();
  }, []);

  const loadModelsAndStartVideo = async () => {
    await Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
      faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
      faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
      faceapi.nets.faceExpressionNet.loadFromUri("/models"),
      faceapi.nets.ssdMobilenetv1.loadFromUri("/models"),
    ]);

    startVideo();
  };

  const startVideo = () => {
    setInterval(detectFace, 100);
  };

  const detectFace = async () => {
    const videoEl = webcamRef?.current?.video;
    const canvas = canvasRef.current;

    if (videoEl && canvas) {
      const detections = await faceapi
        .detectAllFaces(videoEl, new faceapi.TinyFaceDetectorOptions())
        .withFaceLandmarks()
        .withFaceExpressions();

      const displaySize = {
        width: videoConstraints.width,
        height: videoConstraints.height,
      };
      faceapi.matchDimensions(canvas, displaySize);

      const resizedDetections = faceapi.resizeResults(detections, displaySize);

      canvas.style.position = "absolute";
      canvas.style.left = "0";
      canvas.style.top = "0";
      canvas.style.objectFit = "cover";

      canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);

      const drawOptions = {
        lineWidth: 2,
        drawBoxes: true,
        drawScoreText: true, // Set drawScoreText to true to display the confidence score
        scoreTextFontSize: "2rem", // Adjust the font size as needed
        boxColor: "rgba(255, 0, 0, 0.5)",
        boxWidthFactor: 0.8,
        boxHeightFactor: 0.8,
      };

      faceapi.draw.drawDetections(
        canvas,
        faceapi.resizeResults(detections, displaySize),
        drawOptions
      );
      faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
      faceapi.draw.drawFaceExpressions(canvas, resizedDetections);
    }
  };

  const handleCompareImages = async (capturedImage, referencedImage) => {
    try {
      const capturedImageElement = await faceapi.fetchImage(capturedImage);
      const referencedImageElement = await faceapi.fetchImage(referencedImage);

      capturedImageElement.onerror = () => {
        console.log("Error loading captured image.");
        alert("Error loading captured image.");
      };

      referencedImageElement.onerror = () => {
        console.log("Error loading referenced image.");
        alert("Error loading referenced image.");
      };

      await Promise.all([
        new Promise((resolve) => {
          capturedImageElement.onload = resolve;
          capturedImageElement.src = capturedImage;
        }),
        new Promise((resolve) => {
          referencedImageElement.onload = resolve;
          referencedImageElement.src = referencedImage;
        }),
      ]);

      const capturedFace = await faceapi
        .detectSingleFace(capturedImageElement)
        .withFaceLandmarks()
        .withFaceDescriptor();

      const referencedFace = await faceapi
        .detectSingleFace(referencedImageElement)
        .withFaceLandmarks()
        .withFaceDescriptor();

      if (referencedFace && capturedFace) {
        const faceMatcher = new faceapi.FaceMatcher([
          referencedFace.descriptor,
        ]);
        const match = faceMatcher.findBestMatch(capturedFace.descriptor);

        console.log(match);

        // Perform actions based on the match result
        // if (match.distance > 0.1) {
        //   alert(`Face Matched `);
        //   dispatch(markPresent());
        // } else {
        //   alert("Face didn't match");
        // }
      } else {
        console.log("No face detected in images");
      }
    } catch (error) {
      console.error("An error occurred during face detection:", error);
      alert("An error occurred during face detection.");
    }
  };

  const handleCapturePhoto = async () => {
    // const imageSrc = webcamRef.current.getScreenshot();
    // await handleCompareImages(imageSrc, referencedImageURL);
    const imageUrl =
      "https://plypicker.s3.ap-south-1.amazonaws.com/user_1703233221979.png";
    const img = await faceapi.fetchImage(imageUrl);
    console.log(img);
    navigate("/form");
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <h1 className="text-3xl font-bold my-6">Face Attendance System</h1>
      <div className="w-full md:w-2/3 lg:w-1/2 xl:w-1/3 rounded-lg overflow-hidden relative">
        <Webcam
          audio={false}
          mirrored={true}
          ref={webcamRef}
          screenshotFormat="image/png"
          videoConstraints={videoConstraints}
          className="w-full h-auto"
        />
        <canvas
          ref={canvasRef}
          width={videoConstraints.width}
          height={videoConstraints.height}
          className="absolute h-full w-auto top-0 left-0"
        />
      </div>
      <button
        onClick={handleCapturePhoto}
        className="mt-8 px-6 py-3 bg-blue-500 text-white rounded-md text-lg font-semibold transition duration-300 hover:bg-blue-600 focus:outline-none"
      >
        Capture Photo
      </button>
    </div>
  );
}

export default Attendance;
