import React, { useState } from "react";
import Login from "../components/Auth/Login";
import Signup from "../components/Auth/Signup";

function Auth() {
  const [active, setActive] = useState("login");

  return (
    <main className="bg-white min-h-[80vh] flex items-center justify-center p-0 md:p-0">
      <div className="bg-white flex flex-col items-center rounded-xl overflow-hidden lg:flex-row md:w-2/3 2xl:w-1/2 sm:w-1/2">
        <Login active={active} setActive={setActive} />
        <Signup active={active} setActive={setActive} />
        <div className="w-1/2">
          <img
            src="https://media.licdn.com/dms/image/D4D22AQGJoMKROe4K7g/feedshare-shrink_800/0/1685619554325?e=2147483647&v=beta&t=yc5_M-QhiKYcwnw4Wdi1in0hJB5rz_0Lq2FWxOUbDe0"
            alt=""
            className="h-f lg:block hidden"
          />
        </div>
      </div>
    </main>
  );
}

export default Auth;
