import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl, getUserIdfromToken } from "./authSlice";

export const getTripId = () => {
  return localStorage.getItem("tripId");
};

export const startTrip = createAsyncThunk(
  "trip/startTrip",
  async (coordinates, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/trip/startTrip`,
        coordinates
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTripData = createAsyncThunk(
  "trip/monitor",
  async (_, { rejectWithValue }) => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.post(`${baseUrl}/api/trip/getAllTripData`, {
        userId: userId,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const monitorTripData = createAsyncThunk(
  "trip/monitor",
  async (_, { rejectWithValue }) => {
    try {
      const tripId = getTripId();
      const response = await axios.get(`${baseUrl}/api/trip/monitor/${tripId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateDistance = createAsyncThunk(
  "trip/updateDistance",
  async (coordinates, { rejectWithValue }) => {
    try {
      const tripId = getTripId();
      const response = await axios.post(
        `${baseUrl}/api/trip/updateDistance/${tripId}`,
        coordinates
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const endTrip = createAsyncThunk(
  "trip/endTrip",
  async (__, { rejectWithValue }) => {
    try {
      const tripId = getTripId();
      const response = await axios.get(`${baseUrl}/api/trip/endTrip/${tripId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  tripCount: "",
  totalDistanceTravelled: "",
  tripId: "",
  latitude: [],
  longitude: [],
  status: "",
};

const tripSlice = createSlice({
  name: "trip",
  initialState,
  reducers: {
    fillTripData: (state, action) => {
      const { payload } = action;
      state.latitude = payload.latitude;
      state.longitude = payload.longitude;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(startTrip.fulfilled, (state, action) => {
      state.tripId = action.payload.data.tripId;
    });
  },
});

export const { fillTripData } = tripSlice.actions;
export default tripSlice.reducer;
