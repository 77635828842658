import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "./authSlice";

const userId = localStorage.getItem("userId");

export const saveSalesFormData = createAsyncThunk(
  "salesFormData",
  async (formData, { rejectWithValue }) => {
    try {
      const updatedFormData = {
        ...formData,
        userId: localStorage.getItem("userId"),
      };

      const response = await axios.post(
        `${baseUrl}/api/form/handleFormData`,
        updatedFormData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllSalesData = createAsyncThunk(
  "getAllSalesData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/form/getAllSalesData/${userId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editSalesData = createAsyncThunk(
  "editSalesData",
  async (formData, { rejectWithValue }) => {
    try {
      const formId = formData._id;
      const response = await axios.post(
        `${baseUrl}/api/form/editSalesData/${formId}`,
        formData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadImageForSalesForm = createAsyncThunk(
  "user/uploadImage",
  async (image, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("image", image);

      const response = await axios.post(
        `${baseUrl}/api/form/upload-image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
