import { ToastContainer } from "react-toastify";
import "./App.css";
import Auth from "./Pages/Auth";
import { Route, Routes } from "react-router-dom";
import Form from "./Pages/Form";
import SalesForm from "./Pages/SalesForm";
import DataCenter from "./Pages/DataCenter";
import TripData from "./Pages/TripData";
import Attendance from "./components/Attendance";
import Navbar from "./components/Navbar";

function App() {
  return (
    <>
      <ToastContainer />
      <Navbar />
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path="/attendance" element={<Attendance />} />
        <Route path="/form" element={<Form />} />
        <Route path="/salesForm" element={<SalesForm />} />
        <Route path="/dataCenter" element={<DataCenter />} />
        <Route path="/tripData" element={<TripData />} />
      </Routes>
    </>
  );
}

export default App;
