import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { getAllSalesData } from "../redux/slice/formSlice";
import EditSalesData from "../components/SalesForm/EditSalesData";

const DataCenter = () => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [salesData, setSalesData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [activeButton, setActiveButton] = useState("tillNow");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getAllSalesData());
        setSalesData(response.payload?.salesData);
      } catch (error) {
        console.log("Error fetching sales data:", error);
      }
    };
    fetchData();
  }, [dispatch, editMode]);

  const handleEditClick = (data) => {
    setSelectedData(data);
    setEditMode(true);
  };

  const filteredSalesData = salesData?.filter((data) => {
    const salesDataDate = new Date(data.date);
    const today = new Date();

    switch (activeButton) {
      case "Date":
        return (
          salesDataDate.getDate() === selectedDate.getDate() &&
          salesDataDate.getMonth() === selectedDate.getMonth() &&
          salesDataDate.getFullYear() === selectedDate.getFullYear()
        );

      case "week":
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        return salesDataDate >= startOfMonth && salesDataDate <= today;

      case "tillNow":
        return true;

      default:
        return (
          salesDataDate.getDate() === today.getDate() &&
          salesDataDate.getMonth() === today.getMonth() &&
          salesDataDate.getFullYear() === today.getFullYear()
        );
    }
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setActiveButton("Date");
  };

  return (
    <div className="p-8">
      <div className="bg-gray-100 p-6 rounded-lg shadow-md mb-4">
        <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold mb-4">
          Sales Data
        </h2>
        <div className="flex flex-col sm:flex-row items-center justify-between">
          <div className="flex items-center space-x-6 mb-4">
            <button
              className={`${
                activeButton === "Date"
                  ? "bg-black text-white p-1"
                  : "text-gray-600"
              }`}
              onClick={() => setActiveButton("Date")}
            >
              Date
            </button>
            <button
              className={`${
                activeButton === "week"
                  ? "bg-black text-white p-1"
                  : "text-gray-600"
              }`}
              onClick={() => setActiveButton("week")}
            >
              Week
            </button>
            <button
              className={`${
                activeButton === "tillNow"
                  ? "bg-black text-white p-1"
                  : "text-gray-600"
              }`}
              onClick={() => setActiveButton("tillNow")}
            >
              Till Now
            </button>
          </div>
          <div>
            {activeButton === "Date" ? (
              <DatePicker
                className="px-3 py-1 border rounded-lg sm:mt-2"
                selected={selectedDate}
                dateFormat="MM/dd/yyyy"
                onChange={handleDateChange}
              />
            ) : null}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredSalesData?.length > 0
          ? filteredSalesData?.map((data, index) => (
              <div
                key={index}
                className="bg-white shadow-md rounded-lg overflow-hidden relative"
              >
                <div className="bg-gray-200 p-4 flex justify-between items-center">
                  <h3 className="text-lg font-semibold">{data.dataType}</h3>
                  <button
                    onClick={() => handleEditClick(data)}
                    className="text-blue-500"
                  >
                    Edit
                  </button>
                </div>
                <div className="p-4">
                  {Object.entries(data)
                    .filter(
                      ([key, value]) =>
                        ![
                          "_id",
                          "__v",
                          "userId",
                          "date",
                          "dataType",
                          "android",
                          "apple",
                          "gpsLocation",
                          "createdAt",
                          "updatedAt",
                          "imagesURL",
                        ].includes(key) &&
                        !(Array.isArray(value) && value.length === 0)
                    )
                    .map(([key, value]) => (
                      <p key={key}>
                        <strong className="mr-1">
                          {key.charAt(0).toUpperCase() + key.slice(1)}:
                        </strong>{" "}
                        {Array.isArray(value) ? value.join(", ") : value}
                      </p>
                    ))}
                </div>
                {data?.imagesURL ? (
                  <div className="px-6 pb-6">
                    <strong>Uploaded Images</strong>
                    <img src={data?.imagesURL} alt="" />
                  </div>
                ) : null}
              </div>
            ))
          : null}
      </div>
      {editMode === true && (
        <EditSalesData data={selectedData} setEditMode={setEditMode} />
      )}
    </div>
  );
};

export default DataCenter;
