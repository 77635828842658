import React, { useState } from "react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import { RxAvatar } from "react-icons/rx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { fillUserData, signupUser } from "../../redux/slice/authSlice";

function Signup({ active, setActive }) {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [roleInput, setRoleInput] = useState("");
  const dispatch = useDispatch();

  if (active !== "signup") {
    return;
  }

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!nameInput || !emailInput || !passwordInput || !roleInput) {
      console.log(roleInput);
      toast.info("Fill all the details", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    const userInput = {
      name: nameInput,
      email: emailInput,
      password: passwordInput,
      role: roleInput,
    };
    const response = await dispatch(signupUser(userInput));

    if (response?.error) {
      const message = response?.payload?.msg?.publicError;
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    const { name, email, isJoining, role } = response?.payload?.data?.user;
    dispatch(fillUserData({ name, email, isJoining, role }));

    toast.success("Signup successfull. Please login to proceed", {
      position: toast.POSITION.TOP_CENTER,
    });
    setActive("login");
  };
  return (
    <>
      <div className="p-8 lg:w-1/2 sm:p-8 md:w-full">
        <h1 className="text-4xl font-semibold mb-6 text-auth md:text-start text-center">
          Signup
        </h1>

        <form action="" className="flex flex-col">
          <div id="input-field" className="flex flex-col mb-4 relative">
            <div className="absolute top-11 right-5 text-zinc-400">
              <RxAvatar />
            </div>
            <label for="email" className="mb-2 text-gray-700">
              Your Name
            </label>
            <input
              value={nameInput}
              onChange={(e) => setNameInput(e.target.value)}
              type="text"
              placeholder="Enter your name"
              className="px-4 py-2 border-2 border-slate-300 rounded-md max-w-full focus:border-blue-500 focus:outline-none"
            />
          </div>

          <div id="input-field" className="flex flex-col mb-4 relative">
            <div className="absolute top-11 right-5 text-zinc-400">
              <CiMail />
            </div>
            <label for="email" className="mb-2 text-gray-700">
              Your email
            </label>
            <input
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              type="email"
              placeholder="Enter your email"
              className="px-4 py-2 border-2 border-slate-300 rounded-md max-w-full focus:border-blue-500 focus:outline-none"
            />
          </div>

          <div id="input-field" className="flex flex-col mb-4 relative">
            <div
              className="absolute top-11 right-5 text-zinc-400 cursor-pointer"
              onClick={(e) => setIsShowPassword(!isShowPassword)}
            >
              {isShowPassword === false ? (
                <IoEyeOffOutline />
              ) : (
                <IoEyeOutline />
              )}
            </div>
            <label for="Password" className="mb-2 text-gray-700">
              Password
            </label>
            <input
              value={passwordInput}
              onChange={(e) => setPasswordInput(e.target.value)}
              type={isShowPassword === false ? "password" : "text"}
              placeholder="Enter your password"
              className="px-4 py-2 border-2 border-slate-300 rounded-md max-w-full focus:outline-none focus:border-blue-500"
            />
          </div>

          <div id="input-field" className="flex flex-col relative">
            <label className="mb-2 text-gray-700">Role</label>
            <div className="flex justify-start gap-4">
              <button
                className={`px-4 py-2 hover:bg-black hover:text-white ${
                  roleInput === "admin"
                    ? "bg-black text-white"
                    : "bg-white text-black border border-black"
                }`}
                onClick={(e) => {
                  setRoleInput("admin");
                  e.preventDefault();
                }}
              >
                Admin
              </button>
              <button
                className={`px-4 py-2 hover:bg-black hover:text-white ${
                  roleInput === "sales"
                    ? "bg-black text-white"
                    : "bg-white text-black border border-black"
                }`}
                onClick={(e) => {
                  setRoleInput("sales");
                  e.preventDefault();
                }}
              >
                Sales
              </button>
            </div>
          </div>

          <button
            onClick={handleSignup}
            className="mb-3 mt-6 bg-[#ff7703]  text-white text-lg px-4 py-2"
          >
            Signup
          </button>
        </form>
        <button
          onClick={() => setActive("login")}
          className="border-[#ff7703] w-full border text-lg px-4 py-2"
        >
          Login
        </button>
      </div>
    </>
  );
}

export default Signup;
