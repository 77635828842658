import React, { useState } from "react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { fillUserData, loginUser } from "../../redux/slice/authSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function Login({ active, setActive }) {
  const [emailInput, setEmailInput] = useState("");
  const [password, setPassword] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.authReducer);

  if (active !== "login") {
    return;
  }

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!emailInput || !password) {
      toast.info("Enter your email and password", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const userData = { email: emailInput, password };
    const response = await dispatch(loginUser(userData));

    if (response?.error) {
      console.log(response);
      const message = response?.payload?.msg?.publicError;
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const { name, email, isJoining, role } = response?.payload?.data?.user;

    dispatch(fillUserData({ name, email, isJoining, role }));

    toast.success("Login success", {
      position: toast.POSITION.TOP_CENTER,
    });
    navigate("/form");
  };

  return (
    <>
      <div className="p-8 lg:w-1/2 sm:p-8 md:w-full">
        <h1 className="text-4xl font-semibold mb-16 text-auth text-center md:text-start">
          Login
        </h1>

        <form action="" className="flex flex-col">
          <div id="input-field" className="flex flex-col mb-4 relative">
            <div className="absolute top-11 right-5 text-zinc-400">
              <CiMail />
            </div>
            <label className="mb-2 text-gray-700">Your email</label>
            <input
              type="email"
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              placeholder="Enter your email"
              className="px-4 py-2 border-2 border-slate-300 rounded-md max-w-full focus:border-blue-500 focus:outline-none"
            />
          </div>

          <div id="input-field" className="flex flex-col relative">
            <div
              className="absolute top-11 right-5 text-zinc-400 cursor-pointer"
              onClick={(e) => setIsShowPassword(!isShowPassword)}
            >
              {isShowPassword === false ? (
                <IoEyeOffOutline />
              ) : (
                <IoEyeOutline />
              )}
            </div>
            <label for="Password" className="mb-2 text-gray-700">
              Password
            </label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={isShowPassword === false ? "password" : "text"}
              placeholder="Enter your password"
              className="px-4 py-2 border-2 border-slate-300 rounded-md max-w-full focus:outline-none focus:border-blue-500"
            />
          </div>

          <button
            onClick={handleLogin}
            className={`mb-3 mt-6 bg-[#ff7703]  text-white text-lg px-4 py-2 ${
              status === "pending" ? "cursor-wait" : "cursor-pointer"
            }`}
            disabled={status === "pending"}
          >
            Login
          </button>
        </form>
        <button
          onClick={() => setActive("signup")}
          className={`border-[#ff7703] w-full border text-lg px-4 py-2 ${
            status === "pending" ? "cursor-wait" : "cursor-pointer"
          }`}
          disabled={status === "pending"}
        >
          Sign up
        </button>
      </div>
    </>
  );
}

export default Login;
