import React, { useEffect, useState } from "react";
import { getUserIdfromToken } from "../../redux/slice/authSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import {
  endTrip,
  fillTripData,
  monitorTripData,
  startTrip,
  updateDistance,
} from "../../redux/slice/tripSlice";
import TripDataTable from "./TripDataTable";

function getCurrentCoordinates() {
  return new Promise(async (resolve, reject) => {
    if (!navigator.geolocation) {
      reject("Geolocation is not supported by your browser");
    } else {
      try {
        const position = await new Promise((res, rej) => {
          navigator.geolocation.getCurrentPosition(res, rej);
        });
        const { latitude, longitude } = position.coords;
        resolve({ latitude, longitude });
      } catch (error) {
        reject(`Error retrieving location: ${error.message}`);
      }
    }
  });
}

const TripStats = () => {
  const [distanceUpdateInterval, setDistanceUpdateInterval] = useState(null);
  const [tripData, setTripData] = useState([]);

  const dispatch = useDispatch();
  const tripStatus = localStorage.getItem("tripId");

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (!tripStatus) {
  //       try {
  //         const result = await getCurrentCoordinates();
  //         const coordinates = {
  //           ...result,
  //           userId: localStorage.getItem("userId"),
  //         };
  //         const response = await dispatch(updateDistance(coordinates));

  //         storeLatestTime();

  //         toast.success("Distance updated", {
  //           position: toast.POSITION.TOP_CENTER,
  //         });
  //         console.log("Current updates:", response);
  //         // }
  //       } catch (error) {
  //         console.error("Error:", error);
  //       }
  //     }
  //   };

  //   const interval = setInterval(fetchData, 60000);
  // }, [dispatch]);

  function storeLatestTime() {
    let timesArray = JSON.parse(localStorage.getItem("timeArray")) || [];

    const currentDate = new Date();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const formattedTime = `${hours}:${minutes}`;

    timesArray.push(formattedTime);

    localStorage.setItem("timeArray", JSON.stringify(timesArray));
  }

  const getCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };

  useEffect(() => {
    const status = localStorage.getItem("tripStatus");
    if (status !== null) {
      const fetchData = async () => {
        try {
          const result = await getCurrentCoordinates();
          const coordinates = {
            ...result,
            userId: localStorage.getItem("userId"),
          };
          const response = await dispatch(updateDistance(coordinates));

          storeLatestTime();

          toast.success("Distance updated", {
            position: toast.POSITION.TOP_CENTER,
          });
          console.log("Distance updated", response?.payload?.data);
          setTripData(response?.payload?.data);
        } catch (error) {
          console.error("Error:", error);
        }
      };

      const interval = setInterval(fetchData, 60000);
      setDistanceUpdateInterval(interval);
    }
  }, [dispatch]);

  const handleStartTrip = async () => {
    const userId = getUserIdfromToken();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        const coordinates = {
          latitude: latitude,
          longitude: longitude,
          userId: userId,
        };
        const response = await dispatch(startTrip(coordinates));

        setTripData(response.payload.data);

        if (response?.error) {
          const message = response?.payload?.msg?.publicError;
          toast.error(message, {
            position: toast.POSITION.TOP_CENTER,
          });
          return;
        }

        toast.success("Trip Started", {
          position: toast.POSITION.TOP_CENTER,
        });
        storeLatestTime();
        const data = response?.payload?.data;
        dispatch(fillTripData(data));

        const tripId = data?.tripId;
        localStorage.setItem("tripId", tripId);

        const interval = setInterval(async () => {
          try {
            const position = await getCurrentPosition();
            const coordinates = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            const response = await dispatch(updateDistance(coordinates));
            storeLatestTime();
            dispatch(fillTripData(response.payload.data));

            console.log("Distance updated", response?.payload?.data);
            setTripData(response?.payload?.data);

            toast.success("Distance updated", {
              position: toast.POSITION.TOP_CENTER,
            });
          } catch (error) {
            console.error("Error updating distance:", error);
          }
        }, 1 * 60 * 1000);
        localStorage.setItem("tripStatus", "OnGoing");
        setDistanceUpdateInterval(interval);
      });
    }
  };

  const handleEndYourTrip = async () => {
    localStorage.removeItem("tripId");
    localStorage.removeItem("timeArray");
    localStorage.removeItem("tripStatus");
    toast.success("Trip Ended", {
      position: toast.POSITION.TOP_CENTER,
    });
    if (distanceUpdateInterval) {
      clearInterval(distanceUpdateInterval);
      setDistanceUpdateInterval(null);
    } else {
      console.log("No active trip to end.");
    }
    const response = await dispatch(endTrip());
  };

  useEffect(() => {
    if (!tripStatus) {
      return;
    }

    const fetchData = async () => {
      try {
        const response = await dispatch(monitorTripData());
        setTripData(response?.payload?.data);
        dispatch(fillTripData(response.payload.data));
      } catch (error) {
        console.error("Error fetching trip data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <div className="bg-white px-6 rounded-lg mb-4">
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold mb-4">
        Trip Status
      </h2>
      {!tripStatus ? (
        <>
          <button
            className="bg-[#ff7707] text-white p-2"
            onClick={handleStartTrip}
          >
            Start Trip
          </button>
        </>
      ) : (
        <>
          <div className="flex gap-2 items-center">
            <img
              src="https://i.gifer.com/9C4G.gif"
              alt="Start Trip"
              className="mb-3"
              style={{ height: "130px" }}
            />
            <p className="text-lg mb-3">Trip has Started</p>
          </div>
          <TripDataTable tripData={tripData} />
          <button
            className="bg-[#ff7707] mt-4 text-white p-2 rounded-md"
            onClick={handleEndYourTrip}
          >
            End Trip
          </button>
        </>
      )}
    </div>
  );
};

export default TripStats;
