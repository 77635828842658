import React, { useState } from "react";
import InputField from "../InputField";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCheckCircle } from "react-icons/fa";
import FileInput from "../FileInput";

function CustomerForm({ handleGetLocation, handleSubmit }) {
  const [deviceType, setDeviceType] = useState("");
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    contactNumber: "",
    alternateContactNumber: "",
    mailId: "",
    gst: "",
    projectLocationAddress: "",
    pincode: "",
    city: "",
    state: "",
    projectType: "",
    budget: "",
    interestLevel: "",
    clientAddress: "",
    clientPincode: "",
    clientCity: "",
    clientState: "",
    android: false,
    apple: false,
    imagesURL: "",
  });

  const projectTypeOptions = [
    { label: "Residential", value: "Residential" },
    { label: "Commercial", value: "Commercial" },
    { label: "Villa", value: "Villa" },
    { label: "Bungalow", value: "Bungalow" },
    { label: "Individual", value: "Individual" },
    { label: "RHK", value: "RHK" },
    { label: "1 BHK", value: "1 BHK" },
    { label: "2 BHK", value: "2 BHK" },
    { label: "3 BHK", value: "3 BHK" },
    { label: "4 BHK", value: "4 BHK" },
    { label: "5 BHK", value: "5 BHK" },
    { label: "6 BHK & Above", value: "6 BHK & Above" },
  ];

  const interestLevelOptions = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ];

  const handleSelectChange = (name, selectedOption) => {
    setFormData({
      ...formData,
      [name]: selectedOption.value,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageUpload = () => {
    setIsImageUploaded(true);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(formData);
  };

  return (
    <form className="mt-4 space-y-4">
      <InputField
        label="Name"
        type="text"
        name="name"
        placeholder="Enter your name"
        value={formData.name}
        onChange={handleChange}
      />
      <div className="flex gap-2">
        <InputField
          label="Contact Number"
          type="number"
          name="contactNumber"
          placeholder="Enter mobile number"
          value={formData.contactNumber}
          onChange={handleChange}
        />
        <InputField
          label="Alternate Number"
          type="number"
          name="alternateContactNumber"
          placeholder="Enter mobile number"
          value={formData.alternateContactNumber}
          onChange={handleChange}
        />
      </div>
      <InputField
        label="Mail ID"
        type="email"
        id="mailId"
        name="mailId"
        placeholder="Enter email id"
        value={formData.mailId}
        onChange={handleChange}
      />
      <InputField
        label="GST No."
        type="text"
        id="gst"
        name="gst"
        placeholder="Enter your GST"
        value={formData.gst}
        onChange={handleChange}
      />
      <InputField
        label="Project Location Address"
        type="text"
        id="projectLocationAddress"
        name="projectLocationAddress"
        placeholder="Enter project location address"
        value={formData.projectLocationAddress}
        onChange={handleChange}
      />
      <InputField
        label="Pincode"
        type="number"
        id="pincode"
        name="pincode"
        placeholder="Enter Pincode"
        value={formData.pincode}
        onChange={handleChange}
      />
      <div className="flex gap-2 items-center">
        <InputField
          label="City"
          type="text"
          id="city"
          name="city"
          placeholder="Enter City"
          value={formData.city}
          onChange={handleChange}
        />
        <InputField
          label="State"
          type="text"
          id="state"
          name="state"
          placeholder="Enter State"
          value={formData.state}
          onChange={handleChange}
        />
      </div>
      <div>
        <p className="mb-1">Project Type</p>
        <Select
          placeholder="Select Your designation"
          onChange={(selectedOption) =>
            handleSelectChange("projectType", selectedOption)
          }
          options={projectTypeOptions}
        />
      </div>
      <InputField
        label="Budget"
        type="text"
        id="budget"
        name="budget"
        placeholder="Enter your Budget"
        value={formData.budget}
        onChange={handleChange}
      />
      <div>
        <p className="mb-1">Interest Level</p>
        <Select
          placeholder="Choose Your Interest level"
          onChange={(selectedOption) =>
            handleSelectChange("interestLevel", selectedOption)
          }
          options={interestLevelOptions}
        />
      </div>
      <h3 className="text-xl font-semibold text-center mb-4 text-auth">
        Client/Vendor Details
      </h3>
      <InputField
        label="Address"
        type="text"
        id="clientAddress"
        name="clientAddress"
        placeholder="Enter Client Address"
        value={formData.clientAddress}
        onChange={handleChange}
      />
      <div className="flex gap-2 items-center">
        <InputField
          label="City"
          type="text"
          id="clientCity"
          name="clientCity"
          placeholder="Enter City"
          value={formData.clientCity}
          onChange={handleChange}
        />
        <InputField
          label="State"
          type="text"
          id="clientState"
          name="clientState"
          placeholder="Enter State"
          value={formData.clientState}
          onChange={handleChange}
        />
      </div>
      <InputField
        label="Pincode"
        type="number"
        id="clientPincode"
        name="clientPincode"
        placeholder="Enter Pincode"
        value={formData.clientPincode}
        onChange={handleChange}
      />
      <div className="flex items-center">
        <button
          type="button"
          className="btn bg-[#ff7703] text-white px-4 mt-3 mr-5 py-1 rounded-lg"
          onClick={() => handleGetLocation(setFormData, formData)}
        >
          Get Current Location
        </button>
        {formData.gpsLocation ? (
          <FaCheckCircle
            style={{ fontSize: "24px", color: "green", marginTop: "12px" }}
          />
        ) : null}
      </div>
      <div className="flex justify-start items-center">
        <div className="mr-3">
          <p>Device Type</p>
        </div>
        <button
          type="button"
          className={`btn mr-4 border-black border px-2 py-1 ${
            deviceType === "Android"
              ? "bg-black text-white"
              : "bg-white text-black"
          }`}
          onClick={() => setDeviceType("Android")}
        >
          Android
        </button>
        <button
          type="button"
          className={`btn mr-4 border-black border px-2 py-1 ${
            deviceType === "Apple"
              ? "bg-black text-white"
              : "bg-white text-black"
          }`}
          onClick={() => setDeviceType("Apple")}
        >
          Apple
        </button>
      </div>
      <FileInput
        label="Upload Image"
        type="file"
        id="imageUpload"
        name="imageUpload"
        formData={formData}
        setFormData={setFormData}
      />
      <div className="flex justify-between">
        <button
          type="submit"
          className="bg-[#ff7703] text-white py-2 px-4 mt-4 rounded-lg"
          onClick={(e) => handleFormSubmit(e)}
        >
          Submit
        </button>
        <button
          type="button"
          //   onClick={() => navigate("/dataCenter")}
          className="border-[#ff7703] border text-black py-2 px-4 mt-4 rounded-lg"
        >
          Clear
        </button>
      </div>
    </form>
  );
}

export default CustomerForm;
