import React, { useEffect, useState } from "react";
import { getUserIdfromToken } from "../redux/slice/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  endTrip,
  fillTripData,
  startTrip,
  updateDistance,
} from "../redux/slice/tripSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserData = ({ userData, setIsShowForm }) => {
  const dispatch = useDispatch();
  const [distanceUpdateInterval, setDistanceUpdateInterval] = useState(null);

  if (!userData) {
    return null;
  }

  const excludedFields = [
    "image",
    "_id",
    "createdAt",
    "updatedAt",
    "__v",
    "name",
    "isJoining",
  ];

  return (
    <div className="flex flex-col items-center justify-center md:justify-start mt-8">
      <div className="order-1 md:order-1 md:mr-8 mb-8 md:mb-8">
        <img
          src={userData.image || "https://via.placeholder.com/150"}
          alt="User"
          className="w-40 h-40 rounded-full"
        />
      </div>
      <div className="order-2 md:order-2">
        <div className="mb-4">
          <h2 className="text-3xl font-semibold mb-2">{userData.name}</h2>
          {Object.keys(userData)
            .filter((key) => !excludedFields.includes(key))
            .map((key) => (
              <p key={key} className="mb-2">
                <strong className="mr-1 capitalize">{key}:</strong>{" "}
                {userData[key]}
              </p>
            ))}
        </div>
        <div className="flex mt-8 items-center justify-center">
          <button
            onClick={() => setIsShowForm(true)}
            className="mr-4 bg-[#ff7703] text-white py-2 px-4"
          >
            Edit Profile
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserData;
