import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { getTripData, monitorTripData } from "../redux/slice/tripSlice";
import { getAllSalesData } from "../redux/slice/formSlice";
import TripStats from "../components/Trip/TripStats";

function TripData() {
  const [activeButton, setActiveButton] = useState("date");
  const [distanceCovered, setDistanceCovered] = useState(0);
  const [totalTimeWorked, setTotalTimeWorked] = useState("0 hrs");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [tripData, setTripData] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const dispatch = useDispatch();

  const filteredSalesData = salesData?.filter((data) => {
    const salesDataDate = new Date(data.date);
    const today = new Date();

    switch (activeButton) {
      case "date":
        return (
          salesDataDate.getDate() === selectedDate.getDate() &&
          salesDataDate.getMonth() === selectedDate.getMonth() &&
          salesDataDate.getFullYear() === selectedDate.getFullYear()
        );

      case "month":
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        return salesDataDate >= startOfMonth && salesDataDate <= today;

      case "tillNow":
        return true;

      default:
        return (
          salesDataDate.getDate() === today.getDate() &&
          salesDataDate.getMonth() === today.getMonth() &&
          salesDataDate.getFullYear() === today.getFullYear()
        );
    }
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setActiveButton("date");
  };

  // Filtering function

  const filterArraysByLastMonth = (data) => {
    const currentDate = new Date();
    const lastMonthDate = new Date(currentDate);
    lastMonthDate.setMonth(currentDate.getMonth() - 1);

    const lastMonthStartDate = lastMonthDate.toISOString().split("T")[0];
    const currentDateISO = currentDate.toISOString().split("T")[0];

    return data.filter((item) => {
      const startTime = item.startTime.split("T")[0];
      return startTime >= lastMonthStartDate && startTime <= currentDateISO;
    });
  };

  const filterArraysByToday = (data) => {
    const today = new Date().toISOString().split("T")[0];
    return data.filter((item) => item.startTime.includes(today));
  };

  const calculateTotalDistanceAndTime = (data) => {
    let totalDistance = 0;
    let totalTime = 0;

    data.forEach((item) => {
      const startTime = new Date(item?.startTime);
      const endTime = item?.endTime ? new Date(item.endTime) : new Date();

      const timeDifference = endTime - startTime;

      const totalTimeInMinutes =
        timeDifference < 3600000 ? timeDifference / 60000 : 0;

      totalTime += totalTimeInMinutes;

      totalDistance += item?.totalDistanceTraveled;
    });
    const unit = totalTime < 60 ? "min" : "hrs";

    setTotalTimeWorked(`${totalTime?.toFixed(2)} ${unit}`);
    setDistanceCovered(totalDistance);
    console.log("distance", totalDistance);
    console.log("time", `${totalTime} ${unit}`);
  };

  const filterDataBasedOnHeading = (data) => {
    if (activeButton === "date") {
      const filteredArrayToday = filterArraysByToday(data);
      calculateTotalDistanceAndTime(filteredArrayToday);

      console.log("filtered today", filteredArrayToday);
    } else if (activeButton === "month") {
      const filteredArrayLastMonth = filterArraysByLastMonth(data);
      calculateTotalDistanceAndTime(filteredArrayLastMonth);

      // console.log("filtered month", filteredArrayLastMonth);
      // console.log("distance month", distanceCovered);
    } else if (activeButton === "tillNow") {
      calculateTotalDistanceAndTime(tripData);
      console.log("distance tillnow", distanceCovered);
    }
  };

  useEffect(() => {
    const fetchTripData = async () => {
      try {
        const response = await dispatch(getTripData());
        setTripData(response?.payload?.trip);

        filterDataBasedOnHeading(response?.payload?.trip);
      } catch (error) {
        console.log(error);
      }
    };
    fetchTripData();

    // const fetchCurrentTrip = async () => {
    //   try {
    //     const response = await dispatch(monitorTripData());
    //     const data = response?.payload?.data;
    //     tripData.push(data);
    //     calculateTotalDistanceAndTime(tripData);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };

    const fetchData = async () => {
      try {
        const response = await dispatch(getAllSalesData());
        setSalesData(response?.payload?.salesData);
      } catch (error) {
        console.error("Error fetching sales data:", error);
      }
    };

    fetchData();
    // fetchCurrentTrip();
  }, [dispatch, activeButton]);

  // useEffect(() => {
  //   console.log(filteredTripData);
  //   calculateTotalDistanceAndTime(filteredTripData);
  // }, [activeButton, selectedDate]);

  return (
    <div className="container mx-auto mt-8 px-4 sm:px-6 lg:px-8">
      <TripStats />
      <div className="bg-gray-100 p-6 rounded-lg shadow-md">
        <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold mb-4">
          Statistics
        </h2>
        <div className="flex flex-col sm:flex-row items-center justify-between">
          <div className="flex items-center space-x-10 mb-4">
            <button
              className={`${
                activeButton === "date"
                  ? "bg-black text-white p-2"
                  : "text-gray-600"
              }`}
              onClick={() => setActiveButton("date")}
            >
              Date
            </button>
            <button
              className={`${
                activeButton === "month"
                  ? "bg-black text-white p-2"
                  : "text-gray-600"
              }`}
              onClick={() => setActiveButton("month")}
            >
              Month
            </button>
            <button
              className={`${
                activeButton === "tillNow"
                  ? "bg-black text-white p-2"
                  : "text-gray-600"
              }`}
              onClick={() => setActiveButton("tillNow")}
            >
              Till Now
            </button>
          </div>
          {activeButton === "date" ? (
            <div>
              <DatePicker
                className="px-3 py-1 border rounded-lg sm:mt-2"
                selected={selectedDate}
                dateFormat="MM/dd/yyyy"
                onChange={handleDateChange}
              />
            </div>
          ) : null}
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 mt-6">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-3">Distance Covered</h3>
          <p className="text-gray-600">{distanceCovered || 0} metres</p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-3">Total Time Worked</h3>
          <p className="text-gray-600">{totalTimeWorked}</p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-3">
            Number of Leads Generated
          </h3>
          <p className="text-gray-600">{filteredSalesData?.length} leads</p>
        </div>
      </div>
    </div>
  );
}

export default TripData;
